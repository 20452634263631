import classNames from 'classnames'

interface Props {
  currentPage: number
  totalPages: number
  onClickNext: (e: Event) => void
  onClickPrevious: (e: Event) => void
  onClickPage: (e: Event, n: number) => void
  options?: Options
}

interface Options {
  onlyCurrent?: boolean
}

const Pagination = ({ currentPage, totalPages, onClickNext, onClickPrevious, onClickPage, options = {} }: Props)  => {

  const listitems: JSX.Element[] = []

  listitems.push(currentPage === 1 ? (
    <li className="page-item disabled">
      <span className="page-link"> Zurück </span>
    </li>
  ) : (
    <li className="page-item" onClick={(e: Event) => onClickPrevious(e)}>
      <a tabindex="0" href={`#back`} className="page-link">Zurück</a>
    </li>
  ))

  listitems.push(currentPage >= totalPages ? (
    <li className="page-item disabled">
      <span className="page-link">Weiter</span>
    </li> 
  ) : (
    <li className="page-item" onClick={(e: Event) => onClickNext(e)}>
      <a tabindex="0" href={`#next`} className="page-link">Weiter</a>
    </li>
  ))

  if (options.onlyCurrent) {
    listitems.splice(1, 0, (
      <li className="page-item active">
        <a className="page-link" tabindex="0" href={`#${currentPage}`} aria-label={`Seite ${currentPage}`}> {currentPage} </a>
      </li>
    ))
  }
  else {
    listitems.splice(1, 0, ...Array.from({ length: totalPages }).map((e: any, i) => {
        const pageNumber = i+1
        return currentPage === pageNumber ? (
            <li className="page-item active">
              <a className="page-link" tabindex="0" href={`#page${pageNumber}`} aria-label={`Seite ${pageNumber}`}> {pageNumber} </a>
            </li>
          ) : (
            <li className="page-item">
              <a className="page-link" tabindex="0" href={`#page${pageNumber}`} aria-label={`Seite ${pageNumber}`} onClick={(e: Event) => onClickPage(e, pageNumber)}> {pageNumber} </a>
            </li>
          )
      })
    )
  }

  return (
    <nav aria-label="Pagination">
      <ul class="pagination">
        { listitems }
      </ul>
    </nav>
  )
}

export default Pagination
